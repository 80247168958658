import React from 'react'
import logo from '../assets/images/Maine_cycling.png';

const Placeholder = () => {
  return (
    <div className='underConstruction'>
      <img className='constructionLogo' src={logo} alt='logo' />
      <h3>Site is currently under construction</h3>
    </div>
  )
}

export default Placeholder;
