import React from 'react';
import './App.scss';
import Placeholder from './components/placeholder';

function App() {
  return (
    <div className="App">
      <Placeholder />
    </div>
  );
}

export default App;
